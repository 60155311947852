<template>
  <!-- <PostLoading
  v-if="pageLoading"
/>
<pageError
  v-else-if="pageError"
  status="fail"
  custom-header
/> -->
  <div
    class="page-answer-invite"
  >
    <!-- <header class="header">
    <div class="back"
      @click="onBackClickHandler"
    />
    <div class="search-box">
      <div class="icon-search" />
      <input v-model="searchText"
        type="search"
        placeholder="搜索你想邀请的人"
        @search="searchHandler"
      >
    </div>
    <div class="header-main" />
  </header> -->
    <PostHeader :menu-status="false" />
    <div
      v-if="hasInvitedList.length > 0"
      class="page-answer-invite-hasinvited"
    >
      <div class="text">
        已成功邀请 {{ hasInvitedList.length }} 人
      </div>
      <div class="invite-avatar-list">
        <div
          v-for="(item, index) in hasInvitedList.slice(0, maxNum)"
          :key="item.id"
          :style="{
            'right': calcRight(index, maxNum + 1, hasInvitedList.length)
          }"
          class="invite-avatar-item"
        >
          <Avatar
            :avatar="item.iconUrl"
            :account-type="item.accountType"
            :vip-type="item.vipType"
            size="s"
          />
        </div>
        <div
          v-if="hasInvitedList.length > maxNum"
          :style="{
            'right': calcRight(maxNum, maxNum + 1, hasInvitedList.length)
          }"
          class="invite-avatar-item"
        >
          <div class="avatar-shadow">
            +{{ hasInvitedList.length - 6 }}
          </div>
        </div>
      </div>
    </div>
    <InviteAnswerList
      @invited="onInvitedHandler"
      @hasInit="pageLoading = false"
      @error="onInviteAnswerListError"
      full-screen
    />
  <!-- <ScrollView
    :loading="inviteListLoading"
    :error="inviteListError"
    :no-more="inviteListEnd"
    @reachBottom="onReachBottomHandler"
    @bottomErrorClick="onBottomErrorClickHandler"
  >
    <ul class="list">
      <li v-for="(userInfo, index) in inviteList"
        :key="userInfo.uid + index"
      >
        <InviteItem
          :avatar="userInfo.iconUrl"
          :nickname="userInfo.nickName"
          :user-level="userInfo.level"
          :desc="userInfo.description"
          :has-invited="userInfo.hasInvited"
          :scheme-url="userInfo.schemeUrl"
          :uid="userInfo.uid"
          :vip-type="userInfo.vipType"
          :account-type="userInfo.accountType"
          :user-info="userInfo"
          @invite="onInviteHandler(userInfo)"
        />
      </li>
    </ul>
  </ScrollView> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import ScrollView from '@/ui/scroll-view/index.vue';
import PostHeader from '@/component/post-header/index.vue';
import Avatar from '@/ui/avatar/index.vue';
// import PostLoading from '@/component/post-loading/index.vue';
// import pageError from '@/component/post-error/index.vue';
import { getInviteList, inviteAnswer, getMyInviteUsers } from '@/api/answer';
import { popBack } from '@/jsbridge';
import InviteAnswerList from '@/component/invite-answer-list/index.vue';
// import InviteItem from './component/invite-item/index.vue';
import reportDataMixin from '@/plugin/mixin/reportData';

export default {
  name: 'PAnswerInvite',
  components: {
    // ScrollView,
    // InviteItem,
    Avatar,
    PostHeader,
    // PostLoading,
    // pageError,
    InviteAnswerList,
  },
  mixins: [reportDataMixin],
  data() {
    return {
      questionId: '',
      searchText: '',
      inviteListLoading: true,
      inviteListError: false,
      inviteListEnd: false,
      inviteList: [],
      hasInvitedList: [],
      pageLoading: true,
      pageError: false,
      maxNum: 6,
      pageNum: 1,
      pageSize: 20,
      reportDataParams: { // 由mixin使用，上报公共参数
        pageid: 1505,
      },
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    searchText() {
      this.debouncedGetAnswer();
    },
    pageLoading(val) {
      if (!val) {
        this.$closeSkeleton();
      }
    },
  },
  mounted() {
    this.questionId = this.$route.query.questionId;
    if (this.questionId) {
      if (this.isApp) {
        this.$store.dispatch('user/getUserInfo')
          .then(() => {
            if (this.userInfo) {
              this.initPageData();
            } else {
              this.pageError = true;
              this.initPageDataH5();
            }
          })
          .catch((err) => {
            this.pageLoading = false;
            this.pageError = true;
            this.$logger.info('getUserInfo err', err);
          });
      } else {
        this.initPageDataH5();
      }
    } else {
      this.pageLoading = false;
      this.pageError = true;
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
    }),
    initPageData() {
      // this.getInviteList();
      this.getMyInviteUsers();
      this.reportData({
        pageid: '1505',
        moduleid: 1,
        eventtype: 1,
        extendid: 4,
        ext1: this.questionId,
      }, '邀请回答页面曝光');
    },
    initPageDataH5() {
      // 不支持H5展示
    },
    getInviteList() {
      this.inviteListLoading = true;
      getInviteList({
        userId: this.userInfo.uid,
        questionId: this.questionId,
        page: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        this.inviteListLoading = false;
        const inviteList = res.userList
          .map((item) => {
            const userInfo = {
              ...item.userInfo,
              ...item.userRelationCnt,
              hasInvited: item.alreadyInvited,
              reason: item.reason,
            };
            return userInfo;
          }).filter(item => this.inviteList.every(u => u.uid !== item.uid));
        if (this.inviteList.length === 0) {
          this.inviteList = inviteList;
        } else {
          this.inviteList = this.inviteList.concat(inviteList);
        }
        this.inviteListEnd = !!res.ifLast;
        this.pageNum += 1;
      })
        .catch(() => {
          this.inviteListLoading = false;
          this.inviteListError = true;
          if (this.inviteList.length === 0) {
            this.pageError = true;
          } else {
            this.inviteListError = true;
          }
        });
    },
    onReachBottomHandler() {
      if (this.inviteListEnd) return;
      this.getInviteList();
    },
    onBottomErrorClickHandler() {
      this.inviteListError = false;
      this.getInviteList();
    },
    onInvitedHandler(invitedUserInfo) {
      this.hasInvitedList.push(invitedUserInfo);
    },
    onInviteHandler(invitedUserInfo) {
      inviteAnswer({
        questionId: this.questionId,
        userId: this.userInfo.uid,
        invitedUserId: invitedUserInfo.uid,
      }).then(() => {
        this.$toast('邀请成功');
        this.hasInvitedList.push(invitedUserInfo);
        // eslint-disable-next-line no-param-reassign
        invitedUserInfo.hasInvited = true;
      })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('邀请失败');
          }
        });
    },
    calcRight(index, total, num) {
      const a = num > total ? total : num;
      return `${((a - index - 1) / total) * 140}px`;
    },
    debouncedGetAnswer() {
      // console.log('input: ', this.searchText);
    },
    searchHandler() {
      // console.log('search: ', this.searchText);
    },
    onBackClickHandler() {
      if (this.isApp) {
        popBack();
      } else {
        this.$router.back();
      }
    },
    getMyInviteUsers() {
      getMyInviteUsers({
        userId: this.userInfo.uid,
        questionId: this.questionId,
      }).then((res) => {
        this.hasInvitedList = res.userList;
        if (this.inviteList.length > 0) {
          this.inviteList = this.inviteList.map(item => ({
            ...item,
            hasInvited: this.hasInvitedList.some(invitedUser => invitedUser.uid === item.uid) ? true : item.hasInvited,
          }));
        }
      });
    },
    onInviteAnswerListError() {
      this.pageLoading = false;
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
